import { cookieStorage, makePersisted } from "@solid-primitives/storage";
import { type ParentProps, type Signal, type ValidComponent, createContext, createSignal, useContext } from "solid-js";

/**
 * Configuration for the main content tabs.
 */
export const MAIN_CONTENT_TAB_OPTIONS = ["Chats", "Assets", "KitchenSink"] as const;
export type MainContentTabOptions = (typeof MAIN_CONTENT_TAB_OPTIONS)[number];

/**
 * UIState default values.
 */
const state = () =>
  ({
    /**
     * Indicates when a user is in a mobile viewport size.
     */
    isMobile: createSignal(false),
    /**
     * Indicates when a user is in a tablet viewport size.
     */
    isTablet: createSignal(false),
    /**
     * Indicates when a user is in a desktop viewport size.
     */
    isDesktop: createSignal(false),
    /**
     * Temporary signal to indicate whether we are in a single or two column layout.
     *
     * This is required until we have a more robust layout system.
     */
    isTwoColumn: createSignal(false),
    /**
     * Indicates when the left drawer element is open, only has relevance at mobile screen sizes.
     */
    leftDrawer: createSignal(false),
    /**
     * Indicates when the right drawer element is open.
     */
    rightDrawer: createSignal(false),
    /**
     * Right drawer contents.
     */
    rightDrawerContents: createSignal(null as ValidComponent | null),
    /**
     * Indicates when the collections modal element is open.
     */
    modal: createSignal(""),
    /**
     * Collection modal contents.
     */
    modalContents: createSignal(null as ValidComponent | null),
    /**
     * Indicates when the collections modal element is open.
     */
    mainContentTab: createSignal<MainContentTabOptions>("Assets"),
    /**
     * Indicates when the collections context menu is open, as this component is repeatedly used this takes the instance ID as a key.
     */
    collectionsContextMenu: createSignal(""),
    /**
     * Indicates when the collections context menu is open.
     */
    accountContextMenu: createSignal(""),
    /**
     * Pseudo select menu.
     */
    pseudoSelectMenu: createSignal(""),
    /**
     * Left nav sidebar toggle
     */
    sidebarToggle: createSignal<"Chats" | "Collections">("Collections"),
    /**
     * Stores the active collection ID.
     */
    collectionId: createSignal(""),
    /**
     * Stores the active thread ID.
     */
    threadId: createSignal(""),
    /**
     *
     */
    gettingStartedScreenManuallyHidden: createSignal(false),
    /**
     * Persistent state for the public knowledge toggle. Backed by cookie storage.
     */
    usePublicKnowledge: makePersisted(createSignal<boolean>(true), {
      name: "pb_kn:v1",
      storage: cookieStorage,
      storageOptions: { expires: new Date(+new Date() + 3.1536e12) }, // 100-years
    }),
  }) as const;

type UIStateValues = ReturnType<typeof state>;

const UIStateContext = createContext<UIStateValues>(state());

interface Props extends ParentProps {}
export const UIState = (props: Props) => {
  return <UIStateContext.Provider value={state()}>{props.children}</UIStateContext.Provider>;
};

/**
 * UI state for the application.
 */
export const useUIState = () => {
  return useContext(UIStateContext);
};
