import type { IconTypes } from "solid-icons";
import { type ParentProps, Show } from "solid-js";
import { StIcon } from "../icons";
import styles from "./ContextMenuItem.module.css";

interface Props extends ParentProps {
  label: string;
  icon?: IconTypes;
  onClick: () => void;
}

export const ContextMenuItem = (props: Props) => {
  return (
    <li class={styles.contextMenuItem}>
      <button
        class={styles.contextMenuItemButton}
        data-component="context-menu-item"
        type="button"
        tabIndex={0}
        onClick={props.onClick}
      >
        <Show when={props.icon}>
          {/* biome-ignore lint/style/noNonNullAssertion: Biome not detecting <Show> condition */}
          <StIcon class={styles.contextMenuItemIcon} icon={props.icon!} size="1.125rem" />
        </Show>
        <span class={styles.contextMenuItemLabel}>{props.label}</span>
      </button>
    </li>
  );
};
