import { type Placement, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";
import type { IconTypes } from "solid-icons";
import { For, type ParentProps, type Signal, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { classNames } from "~/lib/classNames";
import type { useUIState } from "~/ui/UIState";
import styles from "./ContextMenu.module.css";
import { ContextMenuDivider } from "./ContextMenuDivider";
import { ContextMenuItem } from "./ContextMenuItem";
export interface ContextMenuProps extends ParentProps {
  id: string;
  signal: Signal<string>;
  placement: Placement;
  bound: HTMLElement;
  items: ContextMenuItemProps[];
  class?: string;
  offset?: number;
  strategy?: "fixed" | "absolute";
}

export type ContextMenuItemProps = {
  divider?: boolean;
  label: string;
  icon?: IconTypes;
  onClick: () => void;
};

/**
 * Close overlays
 */
export const resetContextMenus = (state: ReturnType<typeof useUIState>) => {
  const [, setCollectionsContextMenu] = state.collectionsContextMenu;
  const [, setAccountContextMenu] = state.accountContextMenu;

  setCollectionsContextMenu("");
  setAccountContextMenu("");
};

export const ContextMenu = (props: ContextMenuProps) => {
  const [show, setShow] = props.signal;

  const cleanupHandler = () => autoUpdate(props.bound, parentRef(), updatePosition, { elementResize: false });
  const [cleanup, setCleanup] = createSignal<() => void>(cleanupHandler);
  createEffect(() => {
    if (show() === props.id) {
      props.bound.setAttribute("aria-expanded", "true");
      setCleanup(cleanupHandler);
      const button = buttons()[0];
      if (button) {
        button.focus();
      }
    } else {
      props.bound.setAttribute("aria-expanded", "false");
      cleanup()();
    }
  });

  const [buttons, setButtons] = createSignal<HTMLElement[]>([]);
  const [focusPosition, setFocusPosition] = createSignal(0);
  const onKeyDown = (event: KeyboardEvent) => {
    if (buttons().length === 0) return;
    if (event.key === "ArrowUp") {
      if (focusPosition() === 0) return;
      setFocusPosition(focusPosition() - 1);
      const button = buttons()[focusPosition()];
      if (button) {
        button.focus();
      }
    } else if (event.key === "ArrowDown") {
      if (focusPosition() === buttons().length - 1) return;
      setFocusPosition(focusPosition() + 1);
      const button = buttons()[focusPosition()];
      if (button) {
        button.focus();
      }
    } else if (event.key === "Escape") {
      setShow("");
      props.bound.focus();
      setFocusPosition(0);
    } else if (event.key === "Enter" || event.key === " ") {
      const button = buttons()[focusPosition()];
      if (button) {
        button.click();
        setShow("");
        props.bound.focus();
        setFocusPosition(0);
      }
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  const [parentRef, setParentRef] = createSignal<HTMLElement>((<></>) as HTMLElement);

  const updatePosition = () =>
    computePosition(props.bound, parentRef(), {
      strategy: props.strategy ?? "fixed",
      placement: "bottom-start",
      middleware: [offset(props.offset ?? 8), flip(), shift({ padding: 16 })],
    }).then(({ x, y }) => {
      Object.assign(parentRef().style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });

  const onClick = (event: Event) => {
    setShow(show() === props.id ? "" : props.id);
    if (show() === props.id) {
      parentRef().focus();
    } else {
      props.bound.focus();
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  onMount(() => {
    props.bound.addEventListener("click", onClick);
    const cleanup = autoUpdate(props.bound, parentRef(), updatePosition, { elementResize: false });
    setButtons(Array.from(parentRef().querySelectorAll("button")));
    cleanup();
    onCleanup(() => {
      props.bound.removeEventListener("click", onClick);
    });
  });

  return (
    <menu
      class={classNames(styles.contextMenu, show() === props.id ? styles.contextMenuVisible : "", props.class)}
      ref={(ref) => {
        setParentRef(ref);
      }}
      id={props.id}
      onKeyDown={onKeyDown}
    >
      <For each={props.items}>
        {(element) => {
          return (
            <>
              <ContextMenuItem label={element.label} icon={element.icon} onClick={element.onClick} />
              {element.divider === true ? <ContextMenuDivider /> : null}
            </>
          );
        }}
      </For>
      {/* <span
        class={styles.contextMenuArrow}
        ref={(ref) => {
          setArrowRef(ref);
        }}
      /> */}
    </menu>
  );
};
